import {
	type BackgammonBoardData,
	type BackgammonMatchData,
	type BackgammonPlayer,
	BackgammonTurnsVariants,
} from "../types";

export function SocketStartPointToUnityTurn(
	startPoint: number
): BackgammonTurnsVariants {
	switch (startPoint) {
		case 0:
			return BackgammonTurnsVariants.WHITE;
		case 23:
			return BackgammonTurnsVariants.BLACK;

		default:
			return BackgammonTurnsVariants.WHITE;
	}
}
export const SocketMatchDataToBackgammonBoardData = (
	socketData: BackgammonMatchData
) => {
	let currentPlayer: BackgammonPlayer | undefined;
	const data: BackgammonBoardData = {
		...socketData.data,
		players: socketData.data.players.map((player) => {
			if (player.playerId === socketData.data.current_user_id) {
				currentPlayer = player;
			}
			return {
				...player,
				color: SocketStartPointToUnityTurn(player.startingHouse),
			};
		}),
		currentPlayerColor: SocketStartPointToUnityTurn(
			currentPlayer?.startingHouse ?? 0
		),
	};
	return data;
};
export const getSquadFromPlayerId = (
	id: number,
	boardData: BackgammonBoardData
) => {
	return (
		boardData.players.find((player) => player.playerId === id)?.color ?? ""
	);
};

/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";

interface Props<T, S> {
	token: string;
	matchID: string;
	selector: (data: S) => T;
}
function UseSocket<T, S>({ matchID, selector, token }: Props<T, S>) {
	const [boardData, setBoardData] = useState<T | undefined>();

	const matchSocket = useMemo(
		() =>
			io("https://staging.peley.game/matches", {
				path: "/socket.io",
				transports: ["websocket"],
				autoConnect: false,
				auth: {
					token: `Access-Token=${token}`,
				},

				query: {
					EIO: "4",
				},
			}),
		[token]
	);
	type ReconnectResponse = {
		success: boolean;

		meta: {
			error_type: string;
			server_time: Date;
		};
	};
	const reconnect = useCallback(() => {
		matchSocket.emit(
			"reconnect",
			{
				match_id: matchID,
			},
			(response: ReconnectResponse) => {
				if (!response?.success) {
					if (window?.ReactNativeWebView) {
						window.ReactNativeWebView.postMessage(
							JSON.stringify({
								action: "matchInvalid",
								data: {},
							})
						);
						throw Error(response.meta.error_type);
					}
				} else {
					const data = selector(response as S);
					setBoardData(data);
					console.log("board Data", data);
				}
			}
		);
	}, [matchSocket, matchID]);
	useEffect(() => {
		function onConnect() {
			reconnect();
			console.log("connected");
		}

		matchSocket.on("connect", onConnect);

		matchSocket.on("global_error", (data) => {
			console.log(data);
		});

		return () => {
			matchSocket.disconnect();
		};
	}, [matchSocket]);

	const connect = () => {
		matchSocket.connect();
	};
	return {
		matchSocket,
		boardData,
		connect,
		reconnect,
	};
}
export default UseSocket;

/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUnityContext } from "react-unity-webgl";
import {
	type BackgammonBoardData,
	type BackgammonMatchData,
	type DiceData,
	type SocketActionData,
	type SocketDiceSelectData,
	type SocketPiceMovedData,
	type SocketTurnData,
	BackgammonSocketEventVariant,
	CheckerPositionVariants,
	unityEvents,
} from "../../types";

import type { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import {
	getSquadFromPlayerId,
	SocketMatchDataToBackgammonBoardData,
} from "../../helpers";
import { useSocket } from "@/hooks";

interface Props {
	token: string;
	matchID: string;
}

function UseBackgammon({ matchID, token }: Props) {
	const [initialized, setInitialized] = useState(false);
	const {
		unityProvider,
		loadingProgression,
		isLoaded,
		addEventListener,
		removeEventListener,
		sendMessage,
	} = useUnityContext({
		loaderUrl: "/games/Backgammon-3/backgammon.loader.js",
		dataUrl: "/games/Backgammon-3/backgammon.data.br",
		frameworkUrl: "/games/Backgammon-3/backgammon.framework.js.br",
		codeUrl: "/games/Backgammon-3/backgammon.wasm.br",
	});
	const loadingPercentage = useMemo(
		() => Math.round(loadingProgression * 100),
		[loadingProgression]
	);

	const { matchSocket, connect, boardData } = useSocket<
		BackgammonBoardData,
		BackgammonMatchData
	>({
		token,
		matchID,
		selector: SocketMatchDataToBackgammonBoardData,
	});
	// socket connector
	useEffect(() => {
		if (matchSocket.disconnected) {
			connect();
		}
	}, []);
	const HandleSocketTurnUpdate = useCallback(
		(playerId: number) => {
			try {
				if (boardData) {
					const playerColor = getSquadFromPlayerId(playerId, boardData);

					sendMessage("Game Manager", "changeTurn", playerColor);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[boardData, sendMessage]
	);
	// socket events
	useEffect(() => {
		function onDisconnect() {
			setInitialized(false);
			console.log("socket disconnected");
		}

		matchSocket.on("disconnect", onDisconnect);
		matchSocket.on("exception", (error) => {
			console.log(error);
			// TODO: tell back to send error correct and send for react native
		});
		matchSocket.on("game-engine-event", (data: string) => {
			const socketData = JSON.parse(data);

			console.log("game-engine-event", socketData);
			if (initialized && boardData) {
				switch ((socketData as SocketActionData).action) {
					case BackgammonSocketEventVariant.ROLLED_DICE: {
						const diceData = socketData as SocketActionData<DiceData>;
						sendMessage(
							"DiceManager",
							"RollDice",
							diceData.data.diceValues.join(",")
						);

						break;
					}
					case BackgammonSocketEventVariant.DICE_SELECT: {
						const options =
							socketData as SocketActionData<SocketDiceSelectData>;
						const playerColor = getSquadFromPlayerId(
							options.data.playerId,
							boardData
						);

						sendMessage(playerColor, "DeactivePices");
						if (boardData.current_user_id === options.data.playerId) {
							const barOptions = options.data.moveOptions.filter(
								(item) => item.fromPosition.type === CheckerPositionVariants.BAR
							);

							if (barOptions.length === 0) {
								sendMessage(
									"Main Road",
									"PathPointActive",
									[
										...new Set(
											options.data.moveOptions
												.filter(
													(item) =>
														item.fromPosition.type ===
														CheckerPositionVariants.MAIN_BOARD
												)
												.map((option) => option.fromPosition.index)
										),
									].join(",")
								);
							} else {
								sendMessage(
									playerColor,
									"PiceActive",
									barOptions.map((option) => option.checkerId).join(",")
								);
							}
						}

						break;
					}
					case BackgammonSocketEventVariant.PLAYER_MOVE: {
						const options = socketData as SocketActionData<SocketPiceMovedData>;

						const playerColor = getSquadFromPlayerId(
							options.data.playerId,
							boardData
						);

						if (
							options.data.move.toPosition.type === CheckerPositionVariants.OFF
						) {
							sendMessage(
								playerColor,
								"MovePice",
								`${options.data.checkerId},25`
							);
						} else {
							sendMessage(
								playerColor,
								"MovePice",
								`${options.data.checkerId},${options.data.move.toPosition.index}`
							);
						}
						sendMessage("DiceManager", "UseDice", options.data.selectedDice);
						options.data.pipCount.forEach((pipCount) => {
							const playerColor = getSquadFromPlayerId(
								pipCount.playerId,
								boardData
							);
							sendMessage(
								playerColor,
								"UpdatePipCount",
								pipCount.pipCount.toString()
							);
						});

						break;
					}
					case BackgammonSocketEventVariant.TURN_UPDATE: {
						const TurnData = socketData as SocketActionData<SocketTurnData>;

						HandleSocketTurnUpdate(TurnData.data.currentTurnPlayer.playerId);

						break;
					}

					default:
						break;
				}
			}
		});
		return () => {
			matchSocket.off();
		};
	}, [matchSocket, sendMessage, boardData, initialized]);

	// unity listeners
	const HandleDice = useCallback(() => {
		matchSocket.emit("user-game-action", {
			match_id: matchID,
			action_name: "backgammon.roll_dice",
		});
	}, [matchSocket, matchID]);
	const HandleMove = useCallback(
		(...parameters: Array<ReactUnityEventParameter>) => {
			const [piceID] = parameters;

			matchSocket.emit("user-game-action", {
				match_id: matchID,
				action_name: "backgammon.select_piece",
				data: {
					checker_id: piceID ? +piceID : 0,
				},
			});
		},
		[matchID, matchSocket]
	);
	const HandleProfile = useCallback(
		(...parameters: Array<ReactUnityEventParameter>) => {
			const [userId] = parameters;

			if (window?.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "profileClicked",
						data: {
							userId,
						},
					})
				);
			}
		},
		[]
	);
	const HandleDiceSelect = useCallback(
		(...parameters: Array<ReactUnityEventParameter>) => {
			const [DiceValue] = parameters;

			matchSocket.emit("user-game-action", {
				match_id: matchID,
				action_name: "backgammon.select_dice",
				data: {
					player_id: boardData?.current_user_id,
					selected_dice: DiceValue ? +DiceValue : 0,
				},
			});
		},
		[matchID, matchSocket, boardData]
	);
	useEffect(() => {
		addEventListener(unityEvents.DICE_ROLLED, HandleDice);
		addEventListener(unityEvents.PLAYER_MOVED, HandleMove);
		addEventListener(unityEvents.DICE_SELECTED, HandleDiceSelect);
		addEventListener(unityEvents.PROFILE_CLICKED, HandleProfile);

		return () => {
			removeEventListener(unityEvents.DICE_ROLLED, HandleDice);
			removeEventListener(unityEvents.PLAYER_MOVED, HandleMove);
			removeEventListener(unityEvents.PROFILE_CLICKED, HandleProfile);
			removeEventListener(unityEvents.DICE_SELECTED, HandleDiceSelect);
		};
	}, [
		addEventListener,
		removeEventListener,
		HandleDice,
		HandleMove,
		HandleProfile,
		HandleDiceSelect,
	]);
	const getProfile = (id: number) => {
		return boardData?.players_profiles.find((item) => item.user_id === id);
	};
	// initializer
	useEffect(() => {
		if (isLoaded && matchSocket.connected && boardData) {
			sendMessage(
				"Game Manager",
				"setCurrentPlayer",
				boardData.currentPlayerColor
			);
			boardData.players.forEach((player) => {
				sendMessage(
					player.color,
					"GameInitializer",
					JSON.stringify({
						piceList: player.checkers,
						pipCount: player.pipCount,
					})
				);

				const profile = getProfile(player.playerId);

				sendMessage(
					player.color,
					"ActiveProfile",
					JSON.stringify({
						...profile,
						profile_image: profile?.profile_image.replace(".svg", ".png"),
						TimeOutsCount: player.faultCount,
						username: profile?.full_name ?? profile?.username,
					})
				);
			});

			HandleSocketTurnUpdate(boardData.LastTurn.playerId);
			setInitialized(true);
			if (window?.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "gameStarted",
						data: {},
					})
				);
			}
		}
	}, [isLoaded, matchSocket, boardData, sendMessage, HandleSocketTurnUpdate]);
	return {
		loadingPercentage,
		unityProvider,
		isLoaded,
		isconnected: matchSocket.connected,
		initialized,
	};
}
export default UseBackgammon;

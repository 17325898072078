import { MatchData, Profile } from "@/types/global";

export interface Position {
	type: CheckerPosition;
	index: number;
}
export interface Checker {
	id: number;

	position: Position;
}
export enum CheckerPositionVariants {
	MAIN_BOARD = "MAIN_BOARD",
	BAR = "BAR",
	OFF = "BEAR_OFF",
}

export type CheckerPosition =
	(typeof CheckerPositionVariants)[keyof typeof CheckerPositionVariants];

export interface BackgammonPlayer {
	playerId: number;
	isLeftTheGame: boolean;
	isConnect: boolean;
	checkers: Checker[];
	startingHouse: number;
	endHouse: number;
	pipCount: number;
	faultCount: number;
	color: BackgammonTurnsVariants;
}
export const enum BackgammonTurnsVariants {
	BLACK = "Black Squad",
	WHITE = "White Squad",
}
export interface LastTurn {
	turnCount: number;
	step: string;
	playerId: number;
	initialDice: InitialDice[];
}

export interface InitialDice {
	playerId: number;
	diceValue: number;
}
export interface BackgammonBoardData {
	id: string;
	stateVersion: number;
	playersAreReady: boolean;
	players: Array<BackgammonPlayer>;
	continuousSixDiceCount: number;
	LastTurn: LastTurn;
	current_user_id: number;
	currentPlayerColor: BackgammonTurnsVariants;
	players_profiles: Profile[];
	remainingTime: number;
}
export type BackgammonMatchData = MatchData<BackgammonBoardData>;
export const enum unityEvents {
	DICE_ROLLED = "DiceRoll",
	PLAYER_MOVED = "PlayerMove",
	PROFILE_CLICKED = "ProfileClicked",
	DICE_SELECTED = "DiceSelected",
}
export const enum BackgammonSocketEventVariant {
	ROLLED_DICE = "backgammon.rolled_dice",
	TURN_UPDATE = "backgammon.turn_updated",
	DICE_SELECT = "backgammon.dice_selected",
	PLAYER_MOVE = "backgammon.checker_moved",
	TIME_OUT = "backgammon.turn_timed_out",
	END_GAME = "engine.match_result",
	PLAYER_LEFT = "engine.player_left",
}
export type BackgammonSocketEventType =
	(typeof BackgammonSocketEventVariant)[keyof typeof BackgammonSocketEventVariant];
export interface SocketActionData<T = undefined> {
	id: string;
	matchId: string;
	action: BackgammonSocketEventType;
	stateVersion: number;
	serverTime: Date;
	data: T;
}
export interface DiceData {
	playerId: number;
	diceValues: number[];
}
export interface MoveOption {
	checkerId: number;
	fromPosition: Position;
	toPosition: Position;
}
export interface SocketDiceSelectData {
	playerId: number;
	selectedDice: number;
	moveOptions: MoveOption[];
}
export interface SocketPipCountData {
	playerId: number;
	pipCount: number;
}
export interface SocketPiceMovedData {
	playerId: number;
	checkerId: number;
	selectedDice: number;
	move: MoveOption;
	pipCount: SocketPipCountData[];
}
export interface SocketTurnData {
	currentTurnPlayer: BackgammonPlayer;
}

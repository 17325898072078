import FaceNormalSVG from "@/components/icons/logo";
import type { FunctionComponent } from "react";

const HomePage: FunctionComponent = () => {
	return (
		<div className="h-screen flex-col gap-4 w-full bg-pink-500  text-white flex justify-center items-center text-6xl uppercase">
			<FaceNormalSVG />
			Peley game hub ;
		</div>
	);
};

export default HomePage;

import { Unity } from "react-unity-webgl";
import { useParams } from "@tanstack/react-router";
import { useLudo } from "./Hooks";
import UseDevicePixelRatio from "@/hooks/UseDevicePixelRatio";
import GameWrapper from "@/components/GameWrapper";
import NetworkStatusText from "@/components/NetworkStatusText/intex";
import GameProgressBar from "./GameProgressBar";

function Ludo() {
	const { matchID, token } = useParams({
		from: "/ludo/$token/$matchID",
	});
	const { isLoaded, loadingPercentage, unityProvider, isconnected } = useLudo({
		matchID,
		token,
	});
	const { devicePixelRatio } = UseDevicePixelRatio();

	return (
		<GameWrapper>
			{isLoaded && !isconnected && (
				<NetworkStatusText text="Waiting for network..." />
			)}
			{!isLoaded && <GameProgressBar percent={loadingPercentage} />}
			{isconnected && (
				<Unity
					className="size-full"
					devicePixelRatio={devicePixelRatio}
					unityProvider={unityProvider}
				/>
			)}
		</GameWrapper>
	);
}

export default Ludo;

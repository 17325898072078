interface Props {
	progress: number;
}

function LoaderProgress({ progress }: Props) {
	return (
		<>
			<div className="mx-auto z-10 relative w-3/5 bg-[#ecf7d4] p-4 rounded-full h-fit gap-3 shadow-custom">
				<div className="w-full bg-[#2b5770] rounded-full h-6 overflow-hidden">
					<div
						className="bg-[#0098f0] h-full p-[2px] rounded-full"
						style={{
							width: `${progress}%`,
						}}
					></div>
				</div>
			</div>
		</>
	);
}

export default LoaderProgress;

import image from "@/assets/Animitions/backgammon.json";
import logo from "@/assets/images/logo/bg-loading.png";
import LoaderProgress from "@/components/LoaderProgress";
import Lottie from "react-lottie-player";
interface Props {
	percent: number;
}

function GameProgressBar({ percent }: Props) {
	return (
		<div className=" gap-2  pt-10 flex items-center  flex-col text-white text-3xl size-full  bg-backgammon">
			<img alt="bg-log" className="h-auto max-w-xs w-full" src={logo} />
			<div className="w-full  ">
				<LoaderProgress progress={percent} />
			</div>
			<Lottie
				play
				animationData={image}
				style={{
					width: "100%",
				}}
			/>
		</div>
	);
}

export default GameProgressBar;

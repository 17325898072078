import ErrorImage from "@/assets/images/error.svg";

interface Props {
	text: string;
}
function NetworkStatusText({ text }: Props) {
	return (
		<div className="text-center gap-4 flex justify-center  flex-col items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-full text-base leading-5  py-8 ">
			<img alt="peley" className="size-28" src={ErrorImage} />
			<p className="text-center max-w-sm">{text}</p>
		</div>
	);
}

export default NetworkStatusText;

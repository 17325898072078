/* eslint-disable camelcase */

import { useCallback, useEffect, useMemo, useState } from "react";
import { useUnityContext } from "react-unity-webgl";
import type { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import {
	type DiceData,
	type Player,
	type SocketActionData,
	type SocketHitData,
	type SocketMoveData,
	type SocketResultData,
	type SocketTurnData,
	type BoardData,
	type LudoMatchData,
	LudoSocketEventVariant,
	unityEvents,
	unityGameObjects,
} from "../../Types";

import {
	socketPlayersToUnitySquads,
	SocketStartPointToUnityTurn,
} from "../../helpers";
import { unityActions } from "../../Constants";
import { useSocket } from "@/hooks";
import { SocketMatchDataToBoardData } from "../../helpers/index";

interface Props {
	token: string;
	matchID: string;
}

function useLudo({ matchID, token }: Props) {
	const {
		unityProvider,
		loadingProgression,
		isLoaded,
		addEventListener,
		removeEventListener,
		sendMessage,
	} = useUnityContext({
		loaderUrl: "/games/Ludo-1/ludo.loader.js",
		dataUrl: "/games/Ludo-1/ludo.data.br",
		frameworkUrl: "/games/Ludo-1/ludo.framework.js.br",
		codeUrl: "/games/Ludo-1/ludo.wasm.br",
	});
	const [initialized, setInitialized] = useState(false);
	const { matchSocket, connect, boardData } = useSocket<
		BoardData,
		LudoMatchData
	>({
		token,
		matchID,
		selector: SocketMatchDataToBoardData,
	});

	const loadingPercentage = useMemo(
		() => Math.round(loadingProgression * 100),
		[loadingProgression]
	);

	const getProfile = (id: number) => {
		try {
			const profile = boardData?.players_profiles.find(
				(item) => item.user_id === id
			);
			return profile;
		} catch (error) {
			console.log(error);
			setInitialized(false);
			if (window?.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "matchInvalid",
					})
				);
			}
			throw error;
		}
	};
	const HandleDice = useCallback(() => {
		matchSocket.emit("user-game-action", {
			match_id: matchID,
			action_name: "ludo.roll_dice",
		});
	}, [matchSocket, matchID]);

	const HandleMove = useCallback(
		(...parameters: Array<ReactUnityEventParameter>) => {
			const [piceID] = parameters;

			matchSocket.emit("user-game-action", {
				match_id: matchID,
				action_name: "ludo.select_piece",
				data: {
					piece_id: piceID ? +piceID : 0,
				},
			});
		},
		[matchSocket, matchID]
	);
	const HandleSocketMove = useCallback(
		(socketData: SocketActionData<SocketMoveData>) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === socketData.data.playerId
				);

				sendMessage(
					player?.squad ?? "",
					"MovePlayerById",
					socketData.data.pieceId.toString()
				);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketTurnUpdate = useCallback(
		(playerId: number, timer = 60) => {
			try {
				if (boardData) {
					const player = boardData?.players.find(
						(player) => player.playerId === playerId
					);

					const SelectedTurn = SocketStartPointToUnityTurn(
						player?.startPoint ?? 0
					);

					setTimeout(() => {
						sendMessage(
							unityGameObjects.GAME_MANAGER,
							unityActions.gameHandlers.changeTurn,
							SelectedTurn
						);
						sendMessage(
							unityGameObjects.DICE_BUTTON,
							boardData.current_user_id === playerId
								? "ActiveDice"
								: "DeactivateDice"
						);
						sendMessage(unityGameObjects.GAME_MANAGER, "TurnAllLightsOff");
						sendMessage(unityGameObjects.DICE_BUTTON, "SetTimer", timer);
						sendMessage(player?.squad ?? "", "SetLightOn");
					}, 1200);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[boardData, sendMessage]
	);
	const HandleActivePice = useCallback(
		(diceData: SocketActionData<DiceData>) => {
			if (boardData && boardData.current_user_id === diceData.data.playerId) {
				const player = boardData?.players.find(
					(player) => player.playerId === diceData.data.playerId
				);

				const SelectedSquad = socketPlayersToUnitySquads(
					player?.startPoint ?? 0
				);
				const ActivePaces = diceData.data.options
					.map((item) => item.pieceId)
					.join(",");

				sendMessage(
					SelectedSquad ?? "",
					unityActions.squadHandlers.ActivePaces,
					ActivePaces
				);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketHit = useCallback(
		(HitData: SocketActionData<SocketHitData>) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === HitData.data.playerId
				);
				setTimeout(() => {
					sendMessage(
						player?.squad ?? "",
						unityActions.squadHandlers.HitPacesByID,
						HitData.data.pieceId.toString()
					);
				}, 700);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketTimeOut = useCallback(
		(currentTurnPlayer: Player) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === currentTurnPlayer.playerId
				);
				sendMessage(
					player?.squad ?? "",
					"SetTimeOuts",
					currentTurnPlayer.faultCount
				);
				sendMessage(player?.squad ?? "", "ResetTimer");
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketWon = useCallback(
		(resultData: SocketActionData<SocketResultData>) => {
			if (boardData) {
				const winner = resultData.data.results.find(
					(player) => player.status === "Won"
				);
				const player = boardData?.players.find(
					(player) => player.playerId === winner?.player_id
				);
				sendMessage(player?.squad ?? "", unityActions.squadHandlers.WonHandler);

				sendMessage(unityGameObjects.DICE_BUTTON, "ResetTimer");

				if (window?.ReactNativeWebView) {
					window.ReactNativeWebView.postMessage(
						JSON.stringify({
							action: "endGame",
							data: {
								...resultData.data,
							},
						})
					);
				}
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketLeft = useCallback(
		(playerData: SocketActionData<Player>) => {
			if (boardData) {
				const player = boardData?.players.find(
					(player) => player.playerId === playerData.data.playerId
				);
				sendMessage(player?.squad ?? "", "DeactivateSquad");
			}
		},
		[boardData, sendMessage]
	);
	const HandleProfile = useCallback(
		(...parameters: Array<ReactUnityEventParameter>) => {
			const [userId] = parameters;

			if (window?.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "profileClicked",
						data: {
							userId,
						},
					})
				);
			}
		},
		[]
	);
	// unity listeners
	useEffect(() => {
		addEventListener(unityEvents.DICE_ROLLED, HandleDice);
		addEventListener(unityEvents.PLAYER_MOVED, HandleMove);
		addEventListener(unityEvents.Profile_Clicked, HandleProfile);

		return () => {
			removeEventListener(unityEvents.DICE_ROLLED, HandleDice);
			removeEventListener(unityEvents.PLAYER_MOVED, HandleMove);
			removeEventListener(unityEvents.Profile_Clicked, HandleProfile);
		};
	}, [
		addEventListener,
		removeEventListener,
		HandleDice,
		HandleMove,
		HandleProfile,
	]);
	// socket
	useEffect(() => {
		function onDisconnect() {
			setInitialized(false);
			console.log("socket disconnected");
		}

		matchSocket.on("disconnect", onDisconnect);
		matchSocket.on("exception", (error) => {
			console.log(error);
			// TODO: tell back to send error correct and send for react native
		});

		matchSocket.on("game-engine-event", (data: string) => {
			const socketData = JSON.parse(data);

			if (initialized) {
				switch ((socketData as SocketActionData).action) {
					case LudoSocketEventVariant.ROLLED_DICE: {
						const diceData = socketData as SocketActionData<DiceData>;

						sendMessage(
							unityGameObjects.DICE_BUTTON,
							unityActions.DiceHandler.changeDiceNumber,
							diceData.data.diceValue
						);

						if (diceData.data.options.length !== 0) {
							HandleActivePice(diceData);
						}
						break;
					}
					case LudoSocketEventVariant.TURN_UPDATE: {
						const TurnData = socketData as SocketActionData<SocketTurnData>;

						HandleSocketTurnUpdate(TurnData.data.currentTurnPlayer.playerId);

						break;
					}
					case LudoSocketEventVariant.PLAYER_MOVE: {
						const TurnData = socketData as SocketActionData<SocketMoveData>;

						HandleSocketMove(TurnData);

						break;
					}
					case LudoSocketEventVariant.PLAYER_HIT: {
						const TurnData = socketData as SocketActionData<SocketMoveData>;

						HandleSocketHit(TurnData);

						break;
					}
					case LudoSocketEventVariant.TIME_OUT: {
						const TurnData = socketData as SocketActionData<Player>;

						HandleSocketTimeOut(TurnData.data);

						break;
					}
					case LudoSocketEventVariant.END_GAME: {
						const gameResult = socketData as SocketActionData<SocketResultData>;

						HandleSocketWon(gameResult);

						break;
					}
					case LudoSocketEventVariant.PLAYER_LEFT: {
						const playerData = socketData as SocketActionData<Player>;

						HandleSocketLeft(playerData);

						break;
					}

					default:
						break;
				}
			}
		});
		return () => {
			matchSocket.off();
		};
	}, [matchSocket, sendMessage, boardData, initialized]);
	// socket connector
	useEffect(() => {
		if (matchSocket.disconnected) {
			connect();
		}
	}, []);
	// initializer
	useEffect(() => {
		if (isLoaded && matchSocket.connected && boardData) {
			sendMessage(
				unityGameObjects.GAME_MANAGER,
				"InitializeBoard",
				boardData.currentPlayerColor
			);
			sendMessage(
				unityGameObjects.GAME_MANAGER,
				"ActiveSquads",
				boardData.players.length
			);

			boardData.players.map((player) => {
				const piceList = player.pieces
					.map((item) => {
						if (item.pieceStatus === "OUT") return "OUT";
						if (item.pieceStatus === "WON") return "56";
						if (
							item.pieceStatus === "PLAYING" &&
							item.position?.type === "SIDE_ROAD"
						)
							return item.position?.index + 51;

						return item.position?.index;
					})
					.join(",");

				sendMessage(player.squad ?? "", "SquadInitializer", piceList);
				const profile = getProfile(player.playerId);
				sendMessage(
					player.squad ?? "",
					"ActiveProfile",
					JSON.stringify({
						...profile,
						profile_image: profile?.profile_image.replace(".svg", ".png"),
						TimeOutsCount: player.faultCount,
						username: profile?.full_name ?? profile?.username,
					})
				);
			});
			setInitialized(true);
			const timer = Math.floor(boardData.remainingTime / 1000);
			if (!boardData.LastTurn.playerId) {
				alert("socket last turn is missed! ");
			}
			HandleSocketTurnUpdate(boardData.LastTurn.playerId, timer);
			if (window?.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "gameStarted",
						data: {},
					})
				);
				window.ReactNativeWebView.postMessage(
					JSON.stringify({
						action: "spectator",
						data: {
							presenceCount: 95,
						},
					})
				);
			}
		}
	}, [
		isLoaded,
		matchSocket.connected,
		boardData,
		sendMessage,
		HandleSocketTurnUpdate,
	]);

	return {
		loadingPercentage,
		unityProvider,
		isLoaded,
		isconnected: matchSocket.connected,
		initialized,
	};
}
export default useLudo;

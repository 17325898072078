import image from "@/assets/Animitions/ludo.json";
import logo from "@/assets/images/logo/ludo-logo.png";
import LoaderProgress from "@/components/LoaderProgress";
import Lottie from "react-lottie-player";
interface Props {
	percent: number;
}

function GameProgressBar({ percent }: Props) {
	return (
		<div className=" gap-2  pt-10 flex items-center  flex-col text-white text-3xl size-full  bg-ludo overflow-hidden">
			<img alt="bg-log" className="h-auto max-w-64 w-full" src={logo} />
			<div className="w-full  ">
				<LoaderProgress progress={percent} />
			</div>
			<Lottie play animationData={image} />
		</div>
	);
}

export default GameProgressBar;

import { useEffect, useState } from "react";

const UseDevicePixelRatio = () => {
	const [devicePixelRatio, setDevicePixelRatio] = useState(
		window.devicePixelRatio
	);
	useEffect(function () {
		// A function which will update the device pixel ratio of the Unity
		// Application to match the device pixel ratio of the browser.
		const updateDevicePixelRatio = function () {
			setDevicePixelRatio(window.devicePixelRatio);
		};
		// A media matcher which watches for changes in the device pixel ratio.
		const mediaMatcher = window.matchMedia(
			`screen and (resolution: ${devicePixelRatio}dppx)`
		);
		// Adding an event listener to the media matcher which will update the
		// device pixel ratio of the Unity Application when the device pixel
		// ratio changes.
		mediaMatcher.addEventListener("change", updateDevicePixelRatio);
		return function () {
			// Removing the event listener when the component unmounts.
			mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
		};
	}, []);
	return {
		devicePixelRatio,
	};
};
export default UseDevicePixelRatio;

import type { ReactNode } from "react";

interface Props {
	children: ReactNode;
}
function GameWrapper({ children }: Props) {
	return (
		<div className=" w-full font-Ubuntu  h-svh flex justify-center items-center bg-white">
			<div className="lg:w-[400px] relative w-full h-full">{children}</div>
		</div>
	);
}

export default GameWrapper;
